import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShippingMethodsList = _resolveComponent("ShippingMethodsList")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_PostCodeList = _resolveComponent("PostCodeList")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_AddPostCode = _resolveComponent("AddPostCode")!
  const _component_EditPostCode = _resolveComponent("EditPostCode")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      class: "taxes-tabs",
      "tab-position": 'top',
      modelValue: _ctx.tabsValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabsValue = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('message.SHIPPING_METHODS')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ShippingMethodsList)
          ]),
          _: 1
        }, 8, ["label"]),
        (_ctx.$can('view_post_code_list'))
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: _ctx.$t('message.POST_CODES')
            }, {
              default: _withCtx(() => [
                (_ctx.postCodeTab == true)
                  ? (_openBlock(), _createBlock(_component_PostCodeList, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_AddPostCode),
    _createVNode(_component_EditPostCode)
  ], 64))
}