import { gql } from "graphql-tag";
import { POST_CODE_FIELDS } from "@/modules/system/post-codes/graphql/Fragments";

export const CREATE_POST_CODE = gql`
	mutation CreatePostCode($from: String, $to: String) {
		create_post_code(from: $from, to: $to) {
			...PostCodeFields
		}
	}
	${POST_CODE_FIELDS}
`;

export const UPDATE_POST_CODE = gql`
	mutation UpdatePostCode($id: Int, $from: String, $to: String) {
		update_post_code(id: $id, from: $from, to: $to) {
			...PostCodeFields
		}
	}
	${POST_CODE_FIELDS}
`;

export const DELETE_POST_CODE = gql`
	mutation DeletePostCode($id: Int!) {
		delete_post_code(id: $id) {
			...PostCodeFields
		}
	}
	${POST_CODE_FIELDS}
`;

export default { CREATE_POST_CODE, UPDATE_POST_CODE, DELETE_POST_CODE };
