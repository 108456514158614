
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input } from "@/components/input-elements";
import { PostCodeAddFormData } from "../interfaces";
import { Apollo, Notify } from "@/core/services";
import { CREATE_POST_CODE } from "../graphql/Mutations";
import { GET_POST_CODES } from "@/modules/system/post-codes/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new post code",
	components: {
		Input,
		// Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const addPostCodeForm = ref<null | HTMLFormElement>(null);

		// Given Site Form Interface
		const postCodeData = ref<PostCodeAddFormData>({
			post_code_from: "",
			post_code_to: "",
		});

		// Submit Handler Request
		const submitHandlerForm = async () => {
			addPostCodeForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					if (postCodeData.value.post_code_from > postCodeData.value.post_code_to) {
						Notify.error(`${i18n.t("message.FROM_SHOULD_BE_LESS_THAN_TO")}`);
						return;
					}
					loading.value = true;
					await Apollo.mutate({
						mutation: CREATE_POST_CODE,
						variables: {
							from: postCodeData.value.post_code_from,
							to: postCodeData.value.post_code_to,
						},
						update: (store, { data: { create_post_code } }) => {
							const pervious_record = store.readQuery({
								query: GET_POST_CODES,
								variables: {
									page: 1,
									limit: 10,
								},
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_POST_CODES,
								variables: {
									page: 1,
									limit: 10,
								},
								data: {
									get_post_codes: {
										...pervious_record.get_post_codes,
										data: [create_post_code, ...pervious_record.get_post_codes.data],
									},
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			addPostCodeForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("addPostCodeAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_post_code")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			postCodeData,
			addPostCodeForm,
			submitHandlerForm,
		};
	},
});
