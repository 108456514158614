
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent, ref, watchEffect } from 'vue'
import ShippingMethodsList from '../components/ShippingMethodsList.vue'
import AddPostCode from "@/modules/system/post-codes/components/AddPostCode.vue";
import EditPostCode from "@/modules/system/post-codes/components/EditPostCode.vue";
import PostCodeList from "@/modules/system/post-codes/components/PostCodeList.vue";
export default defineComponent({
  components: {
    ShippingMethodsList,
		PostCodeList,
		AddPostCode,
		EditPostCode,
  },

  setup() {
    const tabsValue = ref(0)
    const postCodeTab = ref(false)  

    watchEffect(() => {
      if(tabsValue.value == 0) {
        setPageHeader({
          title: "message.SHIPPING_METHODS",
          actionButton: {
            ability: "add_shipping_method",
            pageAction: {
              action: "addShippingMethodAction",
            },
            button: {
              icon: "bi bi-plus",
              openModal: false,
              path: "/system/settings/shipping-methods/add",
            },
          },
          breadCrumbs: [
            { name: "message.HOME", link: "/dashboard" },
            { name: "message.SYSTEM", link: "#" },
            { name: "message.SETTINGS", link: "/system/settings" },
            { name: "message.SHIPPING_METHODS" },
          ],
        });
      } else  {
        postCodeTab.value = true
        setPageHeader({
            title: "message.POST_CODES",
            actionButton: {
                ability:'add_post_code',
                pageAction: {
                  action: "addPostCodeAction",
                },
                button: {
                  icon: "bi bi-plus",
                  openModal: true,
                }
            },
            breadCrumbs: [
                { name: "message.HOME", link: "/dashboard"},
                { name: "message.SYSTEM", link: "#"},
                { name: "message.SETTINGS", link: "/system/settings"},
                { name: "message.POST_CODES"}
            ]
        });
      }
    })

    return {
      tabsValue,
      postCodeTab
    }
  },
});
