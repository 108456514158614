
import { defineComponent, ref, computed, inject, onMounted, onUnmounted, watchEffect } from "vue";
import { useStore } from "vuex";
import { Apollo, Notify } from "@/core/services";
import { DELETE_POST_CODE } from "../graphql/Mutations";
import { GET_POST_CODES, SEARCH_POST_CODES } from "@/modules/system/post-codes/graphql/Queries";
import Search from "../../../../components/search/Search.vue";
import { useI18n } from "vue-i18n";
import Table from "../../../../components/Table/Table.vue";
import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
	name: "site-list",
	components: { Search, Table, InnerLoader },
	setup() {
		const store = useStore();
		const i18n = useI18n();
		const emitter: any = inject("emitter");
		const loader = ref(false);
		const loading = ref(false);
		const permissions = store.getters.getPermissions;
		const activeIndex = ref(-1);
		const postCodeData = ref({});
		const pagination = ref({}) as Record<any, any>;
		const currentPage = ref();
		const clickHandler = ref() as Record<any, any>;
		const searchObservable: any = ref(null);
		const observable: any = ref(null);
		const system_locale = ref();

		const columns = ref([
			{
				label: "message.ID",
				key: "id",
			},
			{
				label: "message.FROM",
				key: "from",
			},
			{
				label: "message.TO",
				key: "to",
			},
			{
				label: "message.CREATED_AT",
				key: "created_at",
				// textAlignment: "center",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		const siteData = computed(() => {
			return store.getters.getAllSites.data.sites;
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const handleEdit = (post_code: Record<any, any>) => {
			emitter.emit("editPostCodeActions", post_code);
		};

		const searchHandler = (page = 1, reload = false) => {
			if (observable.value != null) {
				observable.value?.unsubscribe();
				observable.value = null;
			}
			searchObservable.value?.unsubscribe();
			const searchData = store.getters.getSearchData;
			loader.value = true;
			Apollo.watchQuery({
				query: SEARCH_POST_CODES,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				variables: {
					page: page,
					limit: 10,
					filter: searchData?.data?.select,
					search_key: searchData?.data?.input,
					locale: system_locale.value,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				clickHandler.value = searchHandler;
				postCodeData.value = data?.search_post_codes?.data;
				pagination.value = data?.search_post_codes;
				loader.value = false;
			});
		};

		const getPostCodes = (page = 1, reload = false) => {
			if (searchObservable.value != null) {
				searchObservable.value?.unsubscribe();
				searchObservable.value = null;
			}
			if (page == 1) observable.value?.unsubscribe();
			// const searchDataInput = reload == true ? "" : store.getters.getSearchData.data.input;
			loader.value = true;
			observable.value = Apollo.watchQuery({
				query: GET_POST_CODES,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				variables: {
					page: page,
					limit: 10,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}

				reload == true ? (currentPage.value = 1) : false;
				clickHandler.value = getPostCodes;
				postCodeData.value = data?.get_post_codes?.data;
				pagination.value = data?.get_post_codes;
				loader.value = false;

				postCodeData.value = data?.get_post_codes?.data;
				loader.value = false;
			});
		};

		const handleDelete = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_POST_CODE,
						variables: { id: id },
						update: (store, { data: { delete_post_code } }) => {
							if (searchObservable.value != null) searchHandler(pagination.value.current_page);
							else getPostCodes(pagination.value.current_page);
							loader.value = false;
						},
					});
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		onMounted(() => {
			document.addEventListener("click", handleClickOutside);
			getPostCodes();
		});

		onUnmounted(() => {
			observable.value?.unsubscribe();
			searchObservable.value?.unsubscribe();
		});

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_POST_CODES,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					search_key: searchData?.data?.input ? searchData?.data?.input : "",
					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_post_codes?.data[0]?.path, "_blank");
					loading.value = false;
				}
				loading.value = false;
				sub.unsubscribe();
			});
		};

		const handleCurrentChange = (num: number) => {
			clickHandler.value(num);
		};

		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});

		return {
			siteData,
			columns,
			permissions,
			loader,
			loading,
			handleEdit,
			handleDelete,
			searchHandler,
			exportFile,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
			postCodeData,
			getPostCodes,
			pagination,
			currentPage,
			handleCurrentChange,
		};
	},
});
