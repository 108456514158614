
import { defineComponent, ref, watchEffect, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Apollo, Notify } from '@/core/services';
import { GET_SHIPPING_METHODS, SEARCH_SHIPPING_METHODS } from '../graphql/Queries';
import { DELETE_SHIPPING_METHOD, UPDATE_SHIPPING_POSITION, COPY_SHIPPING_METHOD } from '../graphql/Mutations';
import { useRouter } from 'vue-router';
import Search from '@/components/search/Search.vue';
import Table from '../../../../components/Table/Table.vue';
import { setPageHistory } from '@/core/helpers/toolbar';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';

export default defineComponent({
    name: 'shipping-methods-list',
    components: { Search, Table, InnerLoader },
    setup() {
        const store = useStore();
        const i18n = useI18n();
        const shippingMethods = ref([]);
        const systemLocale = ref();
        const pagination = ref({}) as Record<any, any>;
        const loader = ref(false);
        const loading = ref(false);
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const router = useRouter();
        const search_shipping_query = ref(false);
        const permissions = store.getters.getPermissions;
        const activeIndex = ref(-1);

        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.POSITION',
                key: 'position'
            },
            {
                label: 'message.CREATED_AT',
                key: 'created_at'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const getShippingMethods = (page = 1, reload = false) => {
            loader.value = true;
            search_shipping_query.value = false;
            Apollo.watchQuery({
                query: GET_SHIPPING_METHODS,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                errorPolicy: 'all',
                variables: {
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getShippingMethods;
                shippingMethods.value = data?.shipping_methods?.data;
                pagination.value = data?.shipping_methods;
                loader.value = false;
            });
        };

        watchEffect(() => {
            systemLocale.value = i18n.fallbackLocale.value;
        });

        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        const searchHandler = (page = 1) => {
            const searchData = store.getters.getSearchData;
            search_shipping_query.value = true;
            loader.value = true;
            Apollo.watchQuery({
                query: SEARCH_SHIPPING_METHODS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    search_key: searchData?.data?.input,
                    locale: systemLocale.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                shippingMethods.value = data?.search_shipping_methods?.data;
                pagination.value = data?.search_shipping_methods;
                loader.value = false;
            });
        };

        const updatePosition = (position: number, new_position: number) => {
            if (new_position <= 0) {
                Notify.error(i18n.t('message.POSITION_MUST_BE_GREATER_THAN_0'));
                return;
            }
            if (position == new_position) {
                return;
            }
            const formData = {
                old_position: position,
                new_position: new_position
            };
            loader.value = true;
            Apollo.mutate({
                mutation: UPDATE_SHIPPING_POSITION,
                variables: {
                    input: formData
                },
                update: (store, { data: { update_shipping_position } }) => {
                    loader.value = false;
                    if (update_shipping_position == true) {
                        if (search_shipping_query.value) {
                            searchHandler(pagination.value.current_page);
                        } else {
                            getShippingMethods(pagination.value.current_page);
                        }
                        Notify.success(`${i18n.t('message.POSITION_CHANGED_SUCCESSFULLY')}`);
                    }
                }
            }).catch(() => {
                loader.value = false;
            });
        };

        const handleEdit = (shipping: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });
            router.push({
                name: 'edit-shipping-methods',
                params: { uuid: shipping.uuid }
            });
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_SHIPPING_METHOD,
                        variables: { id: id },
                        update: (store, { data: { delete_shipping_method } }) => {
                            search_shipping_query.value ? searchHandler(pagination.value.current_page) : getShippingMethods(pagination.value.current_page, false);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const handleCopy = (uuid: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_COPY_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: COPY_SHIPPING_METHOD,
                        variables: { shipping_method_id: uuid },
                        update: (store, { data: { copy_shipping_method } }) => {
                            getShippingMethods(pagination.value.current_page, false);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_COPY_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_SHIPPING_METHODS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? searchData?.data?.select : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    locale: systemLocale.value,
                    type: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_shipping_methods?.data[0]?.path, '_blank');
                    loading.value = false;
                }

                loading.value = false;
                sub.unsubscribe();
            });
        };

        onMounted(() => {
            const pageHistory = store.getters.getPageHistory;
            document.addEventListener('click', handleClickOutside);

            if (pageHistory?.filter != undefined || pageHistory?.search_key != undefined) {
                searchHandler(pageHistory?.page_number);
            } else {
                pageHistory.page_number == undefined ? getShippingMethods(1) : getShippingMethods(pageHistory.page_number);
            }
        });

        return {
            systemLocale,
            pagination,
            shippingMethods,
            currentPage,
            columns,
            permissions,
            loading,
            loader,
            exportFile,
            handleEdit,
            handleDelete,
            getShippingMethods,
            updatePosition,
            searchHandler,
            handleCurrentChange,
            activeIndex,
            dropdownHandler,
            handleClickOutside,
            handleCopy
        };
    }
});
